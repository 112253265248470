import React from 'react'
import './App.css'
import MainCmp from './components/MainComponent/MainCmp'
import Navbar from './components/Navbar/Navbar'
import Header from './page/Heder/Header'
import HeroSection from './components/sectionComponent/HeroSection'
import SectionCmp from './components/sectionComponent/SectionCmp'
import OurWorks from './page/OurWorks/OurWorks'
import SectionWideCmp from './components/sectionComponent/SectionWideCmp'
import HowWeDo from './page/HowWeDo/HowWeDo'
import AboutStudio from './page/AboutStudio/AboutStudio'
import Footer from './page/Footer/Footer'
import InitialPage from './components/InitialPage/InitialPage'

function App() {
	return (
		<div className=''>
			<InitialPage />
			{/* <Navbar /> */}
			{/* <MainCmp>
				<HeroSection children={<Header />} />
				<SectionWideCmp children={<OurWorks />} />
				<HowWeDo />
				<SectionCmp children={<AboutStudio />} />
				<Footer />
			</MainCmp> */}
		</div>
	)
}

export default App
