import './initialPage.css'

const InitialPage = () => {
	return (
		<div className='w-screen h-screen flex flex-col justify-center items-center gap-[80vh]'>
			<div className='header-title'>
				<p className=''>Anytime</p>
			</div>
			<div className='header-text'>
				<p className=''>Maybe someday...</p>
			</div>
		</div>
	)
}

export default InitialPage
